<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="isShow"
      width="450px"
      :before-close="handleClose"
      custom-class="payDialog"
    >
      <div class="content">
        <div class="payBond-info">
          <p><i class="el-icon-info"></i>
            请先缴纳保证金，再进行报价</p>
        </div>  
        <div class="btn-warp">
          <el-button @click="handleClose">取消</el-button>
          <el-button class="hy-btn" @click="submit">去支付</el-button>
        </div>
      </div>
    </el-dialog>
    <PaymentBond v-bind="$attrs" v-on="$listeners" :isShow="paymentBondShow" @close="closeDialog('paymentBondShow')"/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      paymentBondShow: false
    }
  },
  props: {
    isShow: {
      type: Boolean
    }
  },
  components: {
    PaymentBond: ()=> import('./paymentBond.vue')
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    closeDialog (tag) {
      this[tag] = false
    },
    submit () {
      this.handleClose()
      this.paymentBondShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .add-user{
    border-radius: 10px !important;
  }
  .el-dialog__header{
    padding: 19px 25px;
    background: #F8F8F8;
    border-radius: 10px 10px 0 0 !important;
  }
  .el-dialog__title{
    font-size: 16px;
  }
  .btn-warp{
    text-align: right;
  }
  .payBond-info{
    text-align: center;
    padding: 30px 0 50px 0;
    i{
      color: #409EFF;
      font-size: 30px;
      vertical-align: middle;
    }
  }
}

</style>